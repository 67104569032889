<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" @keyup.enter.native="goodsSearch" placeholder="标签名称" style="width: 200px;;margin-right: 10px;"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<buttonPermissions :datas="'addStoreLabel'">
					<el-button type="primary" style="margin-left:15px;width:90px;" @click="goodsAdd({})">添加</el-button>
				</buttonPermissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column label="标签名称">
					<template slot-scope="scope">
						<div style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;width: 300px;">{{scope.row.ShopTagName}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="标签门店">
					<template slot-scope="scope">
						<div v-if="scope.row.FromShopTagNum>0" style="color: #409EFF;cursor: pointer;" @click="tagDoorLink(scope.row)">{{scope.row.FromShopTagNum}}</div>
						<div v-else>{{scope.row.FromShopTagNum}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间" width="250"></el-table-column>
				<el-table-column label="操作" width="160">
					<template slot-scope="scope">
						<buttonPermissions :datas="'editStoreLabel'">
							<el-button type="text"  @click="goodsAdd(scope.row)">编辑</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'deleteStoreLabel'">
							<el-button type="text"  @click="deleteChose(scope.row)" style="color:#F56C6C;margin-left:15px">删除</el-button>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<!-- 品牌名称编辑的弹框 -->
		<el-dialog :visible.sync="editVisable" width="600px" :title="TagTitle" :show-close="false">
			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="标签名称" prop="name">
						<el-input v-model="ruleForm.name" placeholder="最多输入16个字" maxlength="16" style="width: 300px;"></el-input>
					</el-form-item>
				</el-form>

				<div style="text-align: center;margin-top:30px;">
					<el-button style="margin-right:30px;width: 120px;" @click="editVisable=false">关闭</el-button>
					<el-button type="primary" style="width: 120px;" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		GetStoretagList,
		EditStoretagInfo,
		DeleteStoretag
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				TagTitle: '添加标签',
				ShopTagId: 0,
				saveLoading: false,
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				ruleForm: {
					name: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入标签名称',
						trigger: 'blur'
					}]
				},
				searchKey: '',
				loading: false,
				editVisable: false,
				groupName: "",
				stopDialogShow: false, //终止提示弹窗
				// startDatePicker: this.beginDate(),
				// endDatePicker: this.processDate(),
				// timeValue: ''
			}
		},
		beforeMount() {
			this.getList()
		},
		methods: {

			// 标签跳转
			tagDoorLink(record) {
				this.$router.push({
					path: '/door/doorManager',
					query: {
						ShopTagId: record.ShopTagId
					}
				});
			},
			//获取标签数据
			async getList() {
				this.loading = true
				try {
					let data = {
						ShopTagName: this.searchKey,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					console.log(data, '需要传递的数据1')
					let result = await GetStoretagList(data)
					console.log(result, '结果')
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			goodsAdd(record) {
				this.editVisable = true
				this.ruleForm.name = ''
				//清除验证
				this.$nextTick(function() {
					this.$refs.ruleForm.clearValidate()
				})

				//编辑
				if (record.ShopTagId) {
					this.TagTitle = '编辑标签'
					this.ShopTagId = record.ShopTagId
					this.ruleForm.name = record.ShopTagName
				} else {
					this.ShopTagId = 0
					this.TagTitle = '添加标签'
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList()
				// this.addProductDialogShow = true
			},
			// eidtGroup(item) {
			// 	this.editVisable = true
			// },
			deleteChose(row) {
				this.$confirm('是否确认将该标签删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let result = await DeleteStoretag({
						ShopTagId: row.ShopTagId
					});
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功！'
						});
						this.currentPage = 1;
						this.getList();
					} else {
						this.$message({showClose: true,
							type: 'error',
							message: result.Message
						});
					}
				})
			},
			// beginDate() {
			// 	const self = this
			// 	return {
			// 		disabledDate(time) {
			// 			if (self.ruleForm.endTime) { //如果结束时间不为空，则小于结束时间
			// 				return new Date(self.ruleForm.endTime).getTime() < time.getTime()
			// 			} else {
			// 				// return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
			// 			}
			// 		}
			// 	}
			// },
			// processDate() {
			// 	const self = this
			// 	return {
			// 		disabledDate(time) {
			// 			if (self.ruleForm.startTime) { //如果开始时间不为空，则结束时间大于开始时间
			// 				return new Date(self.ruleForm.startTime).getTime() > time.getTime()
			// 			} else {
			// 				// return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
			// 			}
			// 		}
			// 	}
			// },
			// timeChange(time){
			// },
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.saveLoading = true
						try {
							let data = {
								ShopTagId: this.ShopTagId,
								ShopTagName: this.ruleForm.name
							}
							let result = await EditStoretagInfo(data)
							if (result.IsSuccess) {
								this.$message({showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.editVisable = false;
								this.getList()
							}
						} catch (err) {

						} finally {
							this.saveLoading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		}
	}
</script>

<style>
</style>
